// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentModel, Language } from './model/environment.model';
import en_US from '../assets/i18n/en_US.json';

export const environment: EnvironmentModel = {
  production: true,
  languages: [
    {name: Language.en_US, value: en_US},
  ],
  onlyMerchantForm: true,
  gtmId: 'GTM-P5MQ9M7',
  appleUrl: 'https://apps.apple.com/hk/app/kaikai-%E8%A1%97%E8%A1%97-%E6%82%A8%E7%9A%84%E7%B7%9A%E4%B8%8B%E8%B3%BC%E7%89%A9%E5%A4%A5%E4%BC%B4/id1580919190?l=en',
  googleUrl: 'https://play.google.com/store/apps/details?id=ai.kaikai.hk',
  defaultPrefix: '+1',
  pageUrl: 'https://us.kaikai.ai/app',
  localeId: 'en',
};
